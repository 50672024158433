<template>
  <div>
    <b-modal id="addstudentmodal" modal-class="modal-right-pane">
      <template v-slot:modal-header="{ close }">
        <h2>{{ $t('new_student') }}</h2>
        <a class="pointer position-relative modal-close" @click="close()">
          <CloseSvg/>
        </a>
      </template>
      <div>
        <h3>{{ $t('general_information') }}</h3>
        <div>
          <b-row>
            <b-col lg="6">
              <InputGroup label="Email"
                          class="default"
                          type="email"
                          v-model="form.email"
                          :readonly="student"/>
            </b-col>
            <b-col lg="6">
              <InputGroup :label="$t('name')"
                          class="default"
                          type="text"
                          v-model="form.name"/>
            </b-col>
            <b-col lg="6">
              <PhoneGroup :label="$t('phone')"
                          class="default"
                          type="text"
                          v-model="form.phone"/>
            </b-col>
          </b-row>
          <div v-if="student && student.id">
            <a class="small text-primary pointer" v-if="!newPass" @click="resetPassword">{{ $t('reset_password') }}</a>
            <div v-if="newPass">{{ $t('new_password') }}: <b>{{ newPass }}</b></div>
          </div>
        </div>
        <h3>{{ $t('level') }}</h3>
        <SelectGroup class="default"
                     valuekey="title"
                     v-model="form.level"
                     :options="levels"
                     title="title"/>
        <h3>{{ $t('photo') }}</h3>
        <div>
          <p class="form-note modal-pl">{{ $t('image_requirements') }}</p>
          <FileGroup accept="image/png,image/jpg,image/jpeg"
                     v-model="image"
                     fid="studentphoto"
                     :label="$t('add_photo')"/>
        </div>
        <div v-if="[1,3].includes($store.state.user.primary_role_id)">
          <div class="d-flex align-items-center">
            <h3 class="d-flex align-items-center">
              {{ $t('price_policy') }}
              <HelpSvg class="help ml-1"  :hover="showTooltip = true" id="p_policy"/>
            </h3>
            <b-popover v-if="showTooltip" target="p_policy" triggers="hover" placement="top" custom-class="gray-popover">
              {{ this.$t('price_policy_info') }}
            </b-popover>
          </div>
          <div v-if="pricepolicies.length > 0">
            <SelectGroup class="default"
                         :key="'pp-pol-'+ppkey"
                         v-model="form.pricepolicy_id"
                         :options="pricepolicies"
                         title="title_price"/>
          </div>
          <a class="text-themed pointer" @click="addPricePolicy">
            <PlusSvg class="svg-themed svg-icon-xs d-inline-block align-middle mr-2"/>
            <div class="d-inline-block align-middle">{{ $t('create_price_policy') }}</div>
          </a>
        </div>
        <div v-if="[4,5].includes($store.state.user.primary_role_id) && teachers.length > 0">
          <h3>{{ $t('teacher') }}</h3>
          <SelectGroup class="default"
                       v-model="form.instructor_id"
                       :options="teachers"
                       title="name"/>
        </div>
        <h3>{{ $t('timezone') }}</h3>
        <SelectGroup class="default"
                     v-model="form.timezone"
                     :options="timezones"
                     title="title"/>
      </div>
      <template v-slot:modal-footer>
        <b-button v-if="student && [4].includes(primaryRoleId)"
                  class="btn-themed btn-white"
                  @click="destroy"
                  :disabled="loading">
          <TrashSvg/>
        </b-button>
        <b-button class="btn-themed" @click="save" :disabled="loading">{{ $t('save') }}</b-button>
      </template>

    </b-modal>
    <AddPricePolicyModal @reload="getPolicies" :policy="null"/>
  </div>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'
import HelpSvg from "@/assets/svg/help.svg"
import InputGroup from "@/views/parts/general/form/InputGroup"
import SelectGroup from "@/views/parts/general/form/SelectGroup"
import FileGroup from "@/views/parts/general/form/FileGroup"
import PhoneGroup from "@/views/parts/general/form/PhoneGroup"
import { StudentsService, PricePoliciesService, DictionaryService, TeachersService } from '@/services/api.service'
import TrashSvg from '@/assets/svg/trash2.svg'
import PlusSvg from '@/assets/svg/plus.svg'
import LangHelper from "@/services/lang.helper"
import moment from 'moment-timezone'
import AddPricePolicyModal from '@/views/pages/instructor/settings/AddPricePolicyModal'

export default {
  components: {
    CloseSvg,
    InputGroup,
    FileGroup,
    PhoneGroup,
    TrashSvg,
    SelectGroup,
    AddPricePolicyModal,
    PlusSvg,
    HelpSvg
  },
  data() {
    return {
      form: {
        email: "",
        name: "",
        phone: "",
        timezone: moment.tz.guess(),
        pricepolicy_id: null,
        instructor_id: null,
        level: null,
      },
      image: null,
      timezones: [],
      pricepolicies: [],
      levels: [],
      teachers: [],
      loading: false,
      ppkey: 0,
      newPass: null
    }
  },
  props: {
    student: { }
  },
  computed: {
    primaryRoleId() {
      return this.$store.state.user ? this.$store.state.user.primary_role_id : null
    },
  },
  mounted() {
    this.timezones = LangHelper.timezonesList()
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === "addstudentmodal") {
        this.newPass = null
        this.loading = false
        console.log(this.student)
        if(this.student) {
          this.form = {
            email: this.student.email,
            name: this.student.name,
            phone: this.student.phone,
            timezone: this.student.timezone,
            pricepolicy_id: this.student.pricepolicy_id,
            instructor_id: this.student.instructor_id,
            level: this.student.level,
          }
        } else {
          this.form = {
            id: "",
            name: "",
            timezone: moment.tz.guess(),
            pricepolicy_id: null,
            instructor_id: null,
            students: []
          }
        }
      }
    })
    this.getPolicies()
    if([4,5].includes(this.primaryRoleId)) {
      TeachersService.get({ for_select: true }).then(res => {
        console.log(res.data.data)
        this.teachers = res.data.data
      })
    }
    if(this.$store.state.dictionary) {
      this.levels = this.$store.state.dictionary.levels
    } else {
      DictionaryService.get().then(res => {
        this.levels = res.data.data.levels
      })
    }

  },
  methods: {
    save() {
      let formData = new FormData()
      if(this.image) {
        formData.append('image', this.image)
      }
      formData = this.appendFormdata(formData, this.form)
      this.loading = true
      if(this.student) {
        StudentsService.update(this.student.id, formData).then(res => {
          let r = this.apiResponse(res)
          if(r.success) {
            this.$bvModal.hide('addstudentmodal')
            this.$toast(this, this.$t('student_changed'))
            this.$emit('refresh')
          } else {
            this.$error(r.data)
          }
          this.loading = false
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      } else {
        StudentsService.add(formData).then(res => {
          let r = this.apiResponse(res)
          if(r.success) {
            if(r.data.password) {
              this.$store.commit('setUserInvitationModalData', {
                password: r.data.password,
                emailSent: true,
                login: this.form.email
              })
            }
            this.$bvModal.show('invitationsentmodal')
            this.$bvModal.hide('addstudentmodal')
            this.$emit('refresh')
          } else {
            this.$error(r.data)
          }
          this.loading = false
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      }
    },
    getPolicies(policy = null) {
      if([1,3].includes(this.$store.state.user.primary_role_id)) {
        PricePoliciesService.get().then(res => {
          console.log(res.data.data)
          this.pricepolicies = res.data.data
          if(policy) {
            this.$set(this.form, 'pricepolicy_id', policy.id)
            this.ppkey = policy.id
          }
        })
      }
    },
    addPricePolicy() {
      this.$bvModal.show('addpricemodal')
    },
    async resetPassword() {
      if(!this.student) return
      let res = await StudentsService.resetPassword(this.student.id)
      this.newPass = res.data.data
    },
    destroy() {
      this.$confirm(this.$t('are_you_sure_you_want_to_delete')).then(() => {
        this.loading = true
        StudentsService.delete(this.student.id).then(() => {
          this.$bvModal.hide('addstudentmodal')
          this.$success(this.$t('student_deleted'))
          this.$emit('refresh')
          this.loading = false
        }).catch((err) => {
          this.errResponse(err)
          this.loading = false
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>


